<template>
  <div>
    <div class="row mb-3">
      <div class="col-12">
        <h1 class="text-white">{{ $t('pages.settings.title') }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <card>
          <template slot="header">
            <h6 class="surtitle">{{ $t('pages.settings.change') }}</h6>
            <h5 class="h3 mb-0">{{ $t('pages.settings.password') }}</h5>
          </template>
          <div class="card-body">
            <validation-observer v-slot="{ invalid }">
              <base-input
                :label="$t('pages.settings.new_password')"
                type="password"
                name="Senha"
                v-model="newPassword"
                :rules="{ required: true, password: confirmation, min: 8 }"
              />
              <base-input
                name="confirmation"
                :label="$t('pages.settings.confirm_password')"
                type="password"
                autocomplete="false"
                v-model="confirmation"
                :rules="{ required: true }"
              />
              <base-button
                type="primary"
                @click="changePassword"
                :disabled="invalid"
              >
                {{ $t('pages.settings.change_password') }}
              </base-button>
            </validation-observer>
          </div>
        </card>
      </div>
      <div class="col-8">
        <app-list type="api-keys" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      newPassword: null,
      confirmation: null,
    }
  },

  methods: {
    ...mapActions({
      updatePassword: 'users/updatePassword',
    }),

    async changePassword() {
      const { ok } = await this.updatePassword({
        newPassword: this.newPassword,
        confirmation: this.confirmation,
      })

      await Swal.fire(
        ok ? 'Senha alterada com sucesso!' : 'Erro ao alterar senha!',
        ok
          ? 'Senha foi alterada com sucesso!'
          : 'Verifique sua senha, ela pode ser muito fraca.',
        ok ? 'success' : 'error'
      )
    },
  },
}
</script>
